.maintanance-mode-container {
  margin: auto;
  max-width: 1024px;
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  h1 {
    font-size: 34px;
  }
  p {
    a {
      color: #002d56;
      font-weight: bold;
    }
  }

  .box {
    width: auto;
    height: 650px;
    background: #fff;
    margin-top: 50px;
    margin-left: 100px;
    margin-right: 100px;
    border-radius: 5px;
    box-shadow: 6px 18px 18px rgba(0, 0, 0, 0.08),
      -6px 18px 18px rgba(0, 0, 0, 0.08);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .maintanance-mode-container .box {
    margin-left: 0;
    margin-right: 0;
  }
}
