.App {
  background: #fbfbfb;
}

//common scss for all required components
// Buttons
.button-box-container {
  display: flex;
  width: 100%;
  gap: 20px;
}

.MuiModal-root {
  z-index: 1200 !important;
}
.signinPopup {
  z-index: 1201 !important;
}
.swal2-container {
  z-index: 1202 !important;
}
.swal2-html-container {
  a {
    color: #002d56;
  }
}
.blank_page_companyLogo {
  display: flex;
  justify-content: center;
  .svg_home {
    width: 50%;
    height: 80vh;
  }
}
.MuiTooltip-popper {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  .MuiTooltip-tooltip {
    border-radius: 0px !important;
    z-index: 1 !important;
    max-width: 60% !important;
    min-width: 100px;
    text-align: center;
    font-weight: 600 !important;
    font-size: 12px;
    background-color: #ffffff;
    border: 1px solid #5c6369;
    color: #002d56;
  }
}
.button-text {
  border-radius: 45px !important;
  border: none !important;
  cursor: pointer;
  box-shadow: 0px 3px 6px #00000029 !important;
  font-weight: 600 !important;
  letter-spacing: 1px !important;
  text-transform: capitalize !important;
  transition: transform 0.2s !important;
  font-size: 18px !important;
}
.glow_effect {
  position: relative;
  overflow: hidden !important;
  max-width: 100%;
  &::after {
    content: "";
    width: 48px;
    height: 200%;
    position: absolute;
    top: -50%;
    left: -100%;
    transform: rotateZ(30deg);
    background: linear-gradient(
      90deg,
      rgba(127, 127, 127, 0.04) 0%,
      rgba(255, 255, 255, 0.24) 50%,
      rgba(127, 127, 127, 0.04) 100%
    );
    animation: 3s linear 0s infinite shineLeftToRight;
    z-index: 2;
  }
}
@keyframes shineRightToLeft {
  from {
    left: 200%;
  }
  to {
    left: -100%;
  }
}
@keyframes shineLeftToRight {
  from {
    left: -100%;
  }
  to {
    left: 200%;
  }
}
.btn-primary {
  background: linear-gradient(180deg, #ffd788 0%, #ad7c20 100%);
  color: #ffffff !important;
  max-width: calc(100% / 4);
}
.btn-secondary {
  background: linear-gradient(180deg, #20639b 0%, #002d56 100%);
  color: #ffffff !important;
  min-width: calc(100% / 3) !important;
}
.btn-ternary {
  // background: linear-gradient(180deg, #f0f0f0 0%, #f0f0f0 100%);
  text-shadow: 0px 3px 6px #00000029;
  color: #002d56 !important;
  border: 1px solid #002d56 !important;
}

.swal2-container {
  .swal2-styled.swal2-confirm {
    background: linear-gradient(180deg, #20639b 0%, #002d56 100%) !important;
  }
  .swal2-styled.swal2-cancel {
    background: linear-gradient(180deg, #ffd788 0%, #ad7c20 100%) !important;
  }
}
.swal2-popup .swal2-html-container {
  #triggerChange {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      display: flex;
      align-items: center;
      gap: 8px;
      p {
        cursor: pointer;
        margin: 0;
        text-decoration: underline;
        font-weight: 400;
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
}

// material ui textbox
.inputfield-box {
  & ::placeholder {
    text-transform: capitalize !important;
    font-size: 16px !important;
    color: #cdd5dd !important;
    opacity: 1 !important;
  }
  .MuiInputLabel-root {
    font-weight: 600 !important;
    font-size: 18px !important;
    color: #3e3e3e !important;
  }
  .MuiOutlinedInput-root {
    input {
      padding: 12px;
      font: unset !important;
      // font-weight: 600 !important;
    }
    &:hover fieldset {
      border-color: #ddb363;
    }
    fieldset {
      legend {
        font-size: 1em !important;
      }
    }
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #ddb363;
  }
  .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #ddb363;
      color: #ddb363;
    }
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #ddb363;
  }
}

.MuiFormControl-root {
  .MuiOutlinedInput-root {
    &:hover fieldset {
      border-color: #ddb363;
    }
    input {
      padding: 14px;
    }
  }
}
.check_country {
  display: flex;
  flex-direction: column;
  gap: 3px;
}

///* dropdown selection background change*/
.MuiAutocomplete-option[aria-selected="true"] {
  color: #fff;
  background-image: linear-gradient(#ffd788, #cc9b3e) !important;
  margin-bottom: 3px;
}
.MuiAutocomplete-option.Mui-focused {
  color: #fff;
  background-image: linear-gradient(#ffd788, #cc9b3e) !important;
}
.MuiAutocomplete-option[aria-selected="true"].Mui-focused {
  color: #fff;
  background-image: linear-gradient(#ffd788, #cc9b3e) !important;
}
.MuiAutocomplete-option[aria-selected="true"].Mui-focused:hover {
  color: #fff;
  background-image: linear-gradient(#ffd788, #cc9b3e) !important;
}

// Nodata found image
.no_data_found {
  width: 280px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    width: 100%;
  }
  p {
    font-size: 16px;
    font-weight: 600;
    color: #002d56;
  }
  .product_not_found {
    color: #3e3e3e;
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;
      font-weight: 400;
      p {
        cursor: pointer;
        margin: 0;
        text-decoration: underline;
        font-weight: 400;
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
}
.MuiTableRow-root td:first-child,
.MuiTableRow-root th:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.MuiTableRow-root td:last-child,
.MuiTableRow-root th:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
//data picker

.calendar_info {
  .MuiOutlinedInput-root {
    padding-right: 0;
    input {
      cursor: pointer;
    }
    svg {
      position: absolute;
      right: 7px;
      opacity: 0.8;
      color: #3e3e3e;
      z-index: -1;
    }
  }
}
.selected_currency_value {
  text-transform: uppercase;
  span {
    .symbol {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    display: flex;
    gap: 0.4rem;
    align-items: center;
    justify-content: center;
    color: #687177;
    font-size: 16px;
    font-weight: 400;
  }
}
.brand_menu {
  .MuiPaper-root {
    padding: 24px;
    max-width: 550px;
    width: 100%;
    top: 106px !important;
    overflow: hidden;
    .brand_filter_popup {
      outline: none;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      .search_container {
        position: relative;
        width: 100%;
        .MuiFormControl-root {
          border-radius: 50px;
          border: 1px solid #e0e0e0;
          max-width: 100%;
          min-width: 100%;
          .MuiInput-root {
            padding: 2px 14px;
            padding-right: 33px;
            &::before,
            &::after {
              display: none;
            }
          }
        }

        .cancel {
          cursor: pointer;
          position: absolute;
          right: 7px;
          top: 7px;
        }
      }
      .search_button_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        background-color: #ffffff;
        padding-left: 8px;

        input {
          &::placeholder {
            width: 100%;
            color: #687177;
            opacity: 1;
            font-size: 16px;
            font-weight: 400;
          }
        }
        .clear_button {
          color: #20639a;
          font-size: 14px;
          font-weight: 600;
          cursor: pointer;
          padding-right: 18px;
          p {
            white-space: nowrap;
            margin: 0;
          }
        }
        @media (max-width: 450px) {
          .css-d5rnkr-MuiInputBase-root .MuiInputBase-input {
            width: calc(68vw - 60px) !important;
          }
        }
      }

      .brand_list {
        max-height: 350px;
        width: 100%;
        position: relative;
        height: calc(100vh - 184px);
        .no_data {
          display: flex;
          justify-content: center;
          align-items: center;
          //brand
        }
        .brand_list_content {
          display: flex;
          width: 100%;
          max-height: 100%;
          overflow-y: auto;
          // position: absolute;
          flex-wrap: wrap;
          column-gap: 16px;
          position: relative;

          .slider_icons {
            min-width: 24px;
            width: 24px;
            height: 24px;
            overflow: hidden;
            border-radius: 9999px;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
              min-width: 100%;
              object-fit: contain;
              min-height: 100%;
            }
            .brand_placeholder {
              margin: 0;
              text-align: center;
            }
          }
          .MuiMenuItem-root {
            max-width: calc(33.3% - 11px);
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
            white-space: nowrap;
            padding: 8px 8px;
            border-left: 1px solid #e0e0e0;
            margin-left: -1px;
            .menu_item_block {
              margin: 0 3px;
              display: flex;
              align-items: center;
              overflow: hidden;
              height: 35px;
              color: #687177;
              font-size: 14px;
              font-weight: 400;
              gap: 8px;
            }
            .MuiTypography-root {
              overflow: hidden;
            }

            @media (max-width: 450px) {
              flex-basis: calc(47% - 8px); /* Adjust as needed */
              max-width: calc(47% - 8px);
            }
          }
        }
      }
    }
  }
}

.MuiModal-root {
  .MuiList-root {
    padding-top: 0;
    padding-bottom: 0;
    .simple_paper {
      height: 35px;
      padding: 0 10px;
      color: #687177;
      font-size: 14px;
      font-weight: 400;
      gap: 8px;
      .MuiCheckbox-root {
        padding: 0px;
      }
      svg {
        color: #20639a;
        width: 18px;
        height: 18px;
      }
    }
  }
}
.MuiPaper-root {
  .MuiDialogContent-root {
    .MuiPickersToolbar-root {
      .MuiGrid-root {
        .MuiPickersToolbar-penIconButton {
          display: none;
        }
      }
    }
    .PrivatePickersToolbar-root {
      background: linear-gradient(180deg, #ffd788 0%, #ad7c20 100%);
      color: #ffffff;
      .MuiTypography-root {
        color: #ffffff;
      }
      .MuiGrid-root {
        .MuiButtonBase-root {
          svg {
            display: none;
          }
        }
      }
    }
    .MuiCalendarPicker-root {
      .PrivatePickersYear-yearButton.Mui-selected,
      .MuiButtonBase-root.MuiPickersDay-root.Mui-selected {
        background: linear-gradient(180deg, #20639b 0%, #002d56 100%);
        color: #ffffff;
      }
    }
  }
}
@-moz-document url-prefix() {
  .drop_mobile_input_required .special-label::before {
    left: 104px !important;
  }
}
@media (max-width: 1660px) {
  .button-text {
    font-size: 16px !important;
  }
  .inputfield-box {
    & ::placeholder {
      font-size: 14px !important;
    }
  }
  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      &:hover fieldset {
        border-color: #ddb363;
      }
      input {
        padding: 12px;
      }
    }
  }
}
@media (max-width: 820px) {
  .button-text {
    font-size: 12px !important;
  }
}

@media (min-width: 1920px) {
  #root {
    max-width: 1920px;
    margin: auto;
  }
}

// lazy_loader_content

.lazy_loader_content {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: #fff;
}

@media (max-width: 580px) {
  .brand_menu .MuiPaper-root {
    padding: 16px;
    left: unset !important;
    top: 0;
  }
  .brand_menu
    .MuiPaper-root
    .brand_filter_popup
    .search_container
    .MuiFormControl-root {
    width: 330px;
  }
}

@media (max-width: 450px) {
  .brand_menu
    .MuiPaper-root
    .brand_filter_popup
    .search_container
    .MuiFormControl-root {
    border-radius: 50px;
    width: 227px;
    border: 1px solid #e0e0e0;
  }
}
